import React from "react";
import YouTube from "../../components/medias/Youtube";


const videos = [
  {
    title: "Teaser 120 Anos de Adventistmo em Portugal",
    video: 'https://www.youtube.com/embed/7W9toPbQNBM'
  },
  {
    title: "Abertura das celebrações dos 120 anos da chegada dos Adventismo a Portugal",
    video: 'https://www.youtube.com/embed/4TZ3ngxNbgs'
  },
  {
    title: "Programa especial na IASD Lisboa Central",
    video: 'https://www.youtube.com/embed/3FH0N5XIyLQ'
  },
  {
    title: "Ted Wilson - 120 Anos Adventismo em Portugal",
    video: 'https://www.youtube.com/embed/MILMv-1U1qM'
  },
  {
    title: "Presidentes da UPASD",
    video: 'https://www.youtube.com/embed/fJX9i0MBOvs'
  },
  {
    title: "Região Eclesiástica Norte",
    video: 'https://www.youtube.com/embed/7SJcBDJeK4U'
  },
  {
    title: "Região Eclesiástica Centro",
    video: 'https://www.youtube.com/embed/VNhrWjHmcNM'
  },
  {
    title: "Região Eclesiástica Sul",
    video: 'https://www.youtube.com/embed/UYZqiEKJ7kY'
  },
  {
    title: "Região Eclesiástica Madeira e Açores",
    video: 'https://www.youtube.com/embed/osVvgRh9jNQ'
  },
  {
    title: "MIDRAS",
    video: 'https://www.youtube.com/embed/MYVwNY3haEE'
  },
  {
    title: "Parque Nacional de Atividades JA da Costa de Lavos",
    video: 'https://www.youtube.com/embed/m-iLI0bJhrs'
  },
  {
    title: "Colégio Adventista de Setúbal",
    video: 'https://www.youtube.com/embed/LUYhR06b8Mk'
  },
  {
    title: "ADRA",
    video: 'https://www.youtube.com/embed/Dj11SAMbUpI'
  },
  {
    title: "ASA",
    video: 'https://www.youtube.com/embed/PuV6-ZftSoY'
  },
  {
    title: "Publicadora SerVir",
    video: 'https://www.youtube.com/embed/uxxjWeiPDQk'
  },
  {
    title: "Entrevista com a Ir.ª Caetana",
    video: 'https://www.youtube.com/embed/lVXnB_KxlLE'
  },
  {
    title: "Entrevista com o Ir.º Gertúlio Fernandes",
    video: 'https://www.youtube.com/embed/Olek-vgvDzE'
  },
  {
    title: "Entrevista com o casal pastoral Ezequiel e Natividade Quintino",
    video: 'https://www.youtube.com/embed/-zexXkNesPM'
  },
  {
    title: "Entrevista com o Ir.º Samuel Ribeiro",
    video: 'https://www.youtube.com/embed/OWID_9RWo5s'
  }
]


function AnniversaryPage() {
  return (
    <div>
      <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 sm:py-32 lg:px-8">
      <img
        alt=""
        src="/images/120anos/background.jpg"
        className="absolute inset-0 -z-10 h-full w-full object-cover"
      />
      <div
        aria-hidden="true"
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
      >
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>
      <div
        aria-hidden="true"
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
      >
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>
      <div className="mx-auto max-w-3xl text-center">
        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Comemoração dos 120 anos de Adventismo em Portugal</h2>
        <p className="mt-6 text-lg leading-8 text-gray-300">
          Um século e duas décadas de fé, comunidade e serviço em terras lusitanas
        </p>
      </div>
    </div>
    <div className="px-4 md:px-20 w-full">
      

      <h2 className="text-4xl mt-12 mb-6 font-bold font-serif">Eventos</h2>
      <h3 className="text-xl mt-12 mb-6 font-bold font-serif">2024</h3>
      <p>Sábado, 28 de setembro - Início das celebrações com culto nacional e progama especial na Novo Tempo Portugal</p>
      <p>Sábado, 23 de novembro - 100 anos IASD Central</p>
      
      <h3 className="text-xl mt-12 mb-6 font-bold font-serif">2025</h3>
      <p>Assembleia Espiritual</p>

      <h3 className="text-xl mt-12 mb-6 font-bold font-serif">2026</h3>
      <p>Cerimónia Baptismal comemorativa dos 120 anos da primeira cerimónia baptismal realizada em Portugal. </p>


      <h2 className="text-2xl mt-12 mb-6 font-bold font-serif">Multimédia</h2>
      <YouTube url='https://www.youtube.com/embed/yXi_eOgkuxw' />


      <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
        >
          {videos.map((video) => (
            <li key={video.name}>
              <YouTube url={video.video} className="aspect-[3/2] w-full rounded-2xl object-cover" />
              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{video.title}</h3>
              <p className="text-base leading-7 text-gray-600">{video.description}</p>
            </li>
          ))}
        </ul>


      <span className="mb-20 block"></span>

    </div>
    </div>
  );
}

export default AnniversaryPage;
