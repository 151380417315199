import React from "react";
import { communityMedias } from "../../data/CommunityMedias";
import MediaBlock from "../blocks/MediaBlock";

function CommunityPage() {
  return (
    <div className="px-4 md:px-20 w-full">
      {/* <div className="grid grid-cols-1 gap-12 mt-12 mb-12 md:grid-cols-3">
      {testimonies.map((testimony) => (<Testimony {...testimony}/>))}
      </div> */}

      <div id="medias" className="mb-64">
        {communityMedias.map((block) => (<MediaBlock {...block}/>))}
      </div>
    </div>
  );
}

export default CommunityPage;
