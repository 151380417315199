/**
 * YouTube component renders an iframe for embedding YouTube videos.
 *
 * @param {string} url - The URL of the YouTube video to embed.
 * @returns {JSX.Element} The iframe element for the YouTube video.
 */
function YouTube(props) {
  return (
    <iframe
      className="aspect-video w-full"
      src={props.url}
      title={props.url}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowFullScreen
    ></iframe>
  );
}

export default YouTube;