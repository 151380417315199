import "./App.css";

import {
  ChannelsPage,
  ContactPage,
  CommunityPage,
  MainPage,
  FaithPage,
  MissingPage,
  FaithSectionPage,
  NextStepPage,
  AnniversaryPage,
} from "./components/pages";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./components/pages/layouts/Layout";
import SimpleLayout from "./components/pages/layouts/SimpleLayout";

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <MissingPage />,
    children: [
      {
        path: "/",
        element: <MainPage />,
      },
      {
        element: <SimpleLayout />,
        children: [
          {
            path: "fe",
            element: <FaithPage />,
          },
          {
            path: "fe/deus",
            element: <FaithSectionPage section="deus" />,
          },
          {
            path: "fe/humanidade",
            element: <FaithSectionPage section="humanidade" />,
          },
          {
            path: "fe/salvacao",
            element: <FaithSectionPage section="salvacao" />,
          },
          {
            path: "fe/igreja",
            element: <FaithSectionPage section="igreja" />,
          },
          {
            path: "fe/vida-crista",
            element: <FaithSectionPage section="vida-crista" />,
          },
          {
            path: "fe/acontecimentos-finais",
            element: <FaithSectionPage section="acontecimentos-finais" />,
          },
        ],
      },
      {
        element: <SimpleLayout />,
        children: [
          {
            path: "comunidade",
            element: <CommunityPage />,
          },
        ],
      },
      {
        element: <SimpleLayout />,
        children: [
          {
            path: "canais",
            element: <ChannelsPage />,
          },
        ],
      },
      {
        element: <SimpleLayout />,
        children: [
          {
            path: "contactos",
            element: <ContactPage />,
          },
        ],
      },
      {
        element: <SimpleLayout />,
        children: [
          {
            path: "para-si",
            element: <NextStepPage />,
          },
        ],
      },
      {
        element: <SimpleLayout />,
        children: [
          {
            path: "120anos",
            element: <AnniversaryPage />,
          },
        ],
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
